import React, { Component, Suspense } from 'react';

const NavbarPage = React.lazy(() => import('../../components/Navbar/Navbar_Page'));
const Section = React.lazy(() => import('./section'));
const About = React.lazy(() => import('../../components/About/about'));
const Process = React.lazy(() => import('../../components/OurProcess/process'));
const Services = React.lazy(() => import('../../components/OurServices/services'));
const OurJourney = React.lazy(() => import('../../components/OurJourney/our-journey'));
const Footer = React.lazy(() => import('../../components/Footer/footer'));
const GetInTouch = React.lazy(() => import('../../components/GetInTouch/GetInTouch'));


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "home", navheading: "Home" },
                { id: 2 , idnm : "about", navheading: "About" },
                { id: 3 , idnm : "services", navheading: "Services" },
                { id: 4 , idnm : "contact", navheading: "Contact" },
            ],
            pos : document.documentElement.scrollTop,
            imglight : true,
            navClass : ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        if(scrollup > this.state.pos)
        {
            this.setState({navClass : "nav-sticky", imglight : false});
        }
        else
        {
            this.setState({navClass : "", imglight : true});
        }
    };

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Suspense fallback = {this.Loader()} >

                    {/* Importing Navbar */}
                    <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} imglight={this.state.imglight} />

                    {/* Importing Section */}
                    <Section/>

                    {/* Importing About Us */}
                    <About/>

                    {/* Importing Our Process */}
                    <Process/>

                    {/* Importing Our Sevices */}
                    <Services />

                    {/* Importing Our Journey */}
                    <OurJourney />

                    {/* Importing Get In Touch */}
                    <GetInTouch/>
                    
                    {/* Importing Footer */}
                    <Footer/>
                </Suspense>

            </React.Fragment>
        );
    }
}

export default Home;